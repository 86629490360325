export interface NavRoute {
  active?: boolean;
  label: string;
  path: string;
}

export const routes: NavRoute[] = [
  { label: 'Welcome', path: '#welcome', active: true },
  { label: 'About us', path: '#about-us' },
  { label: 'Work', path: '#featured-work' },
  { label: 'Team', path: '#team' },
  { label: 'Blog', path: '#blog' },
  { label: 'Contact', path: '#contact' },
];

export const socialMediaLinks = {
  facebook: 'https://www.facebook.com/Draxo.co',
  linkedIn: 'https://co.linkedin.com/company/draxo-co',
  instagram: 'https://www.instagram.com/draxo.co/',
};

export const contact = {
  meeting: {
    href: 'https://calendly.com/info-8o4/',
    label: 'Shedule a meeting',
  },
  phone: {
    href: 'https://wa.me/+573113656546',
    label: '+57 (311) 365 6546',
  },
  mail: {
    href: 'mailto:info@draxo.co',
    label: 'info@draxo.co',
  },
};
