import { createContext } from 'react';

import { NavState } from '.';

interface NavContextProps extends NavState {
  routeSelector: (value: -1 | 1) => void;
  setHideNav: (value: boolean) => void;
  setRoute: (routePath: string) => void;
  toogleNav: () => void;
}

export const NavContext = createContext({} as NavContextProps);
