import styled from 'styled-components';

import { FontSize, FontWeiht, LineHeight } from '../../../models/theme.model';

export const H1Style = styled.h1<{
  fontWeight?: keyof FontWeiht;
  fontSize?: keyof FontSize;
  lineHeight?: keyof LineHeight;
}>`
  font-size: ${({ theme, fontSize }) =>
    fontSize
      ? theme.typography.fontSize[fontSize]
      : theme.typography.fontSize.h1};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  line-height: ${({ theme, lineHeight }) =>
    theme.typography.lineHeight[lineHeight || 80]};
`;

export const H2Style = styled(H1Style.withComponent('h2'))`
  line-height: ${({ theme, lineHeight }) =>
    theme.typography.lineHeight[lineHeight || 70]};
`;
export const H3Style = styled(H1Style.withComponent('h3'))`
  line-height: ${({ theme, lineHeight }) =>
    theme.typography.lineHeight[lineHeight || 60]};
`;
export const H4Style = styled(H1Style.withComponent('h4'))`
  line-height: ${({ theme, lineHeight }) =>
    theme.typography.lineHeight[lineHeight || 50]};
`;
export const H5Style = styled(H1Style.withComponent('h5'))`
  line-height: ${({ theme, lineHeight }) =>
    theme.typography.lineHeight[lineHeight || 40]};
`;

export const TitleStyle = styled.h6<{
  fontWeight?: keyof FontWeiht;
  fontSize: 'title1' | 'title2';
  lineHeight?: keyof LineHeight;
}>`
  font-size: ${({ theme, fontSize }) => theme.typography.fontSize[fontSize]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  line-height: ${({ theme, fontSize, lineHeight }) =>
    fontSize === 'title1'
      ? theme.typography.lineHeight[lineHeight || 36]
      : theme.typography.lineHeight[lineHeight || 30]};
`;

export const SubTitleStyle = styled.h6<{
  fontWeight?: keyof FontWeiht;
  fontSize: 'subTitle1' | 'subTitle2';
  lineHeight?: keyof LineHeight;
}>`
  font-size: ${({ theme, fontSize }) => theme.typography.fontSize[fontSize]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  line-height: ${({ theme, fontSize, lineHeight }) =>
    fontSize === 'subTitle1'
      ? theme.typography.lineHeight[lineHeight || 26]
      : theme.typography.lineHeight[lineHeight || 22]};
`;

export const BodyTextStyle = styled.p<{
  fontWeight?: keyof FontWeiht;
  fontSize: 'bodyText1' | 'bodyText2';
  lineHeight?: keyof LineHeight;
}>`
  font-size: ${({ theme, fontSize }) => theme.typography.fontSize[fontSize]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  line-height: ${({ theme, fontSize, lineHeight }) =>
    fontSize === 'bodyText1'
      ? theme.typography.lineHeight[lineHeight || 20]
      : theme.typography.lineHeight[lineHeight || 18]};
`;

export const ParagraphStyle = styled.p<{
  fontWeight?: keyof FontWeiht;
  fontSize: 'paragraph1' | 'paragraph2';
  lineHeight?: keyof LineHeight;
  upperCase?: boolean;
}>`
  font-size: ${({ theme, fontSize }) => theme.typography.fontSize[fontSize]};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight
      ? theme.typography.fontWeight[fontWeight]
      : theme.typography.fontWeight.regular};
  line-height: ${({ theme, fontSize, lineHeight }) =>
    fontSize === 'paragraph1'
      ? theme.typography.lineHeight[lineHeight || 16]
      : theme.typography.lineHeight[lineHeight || 15]};
  letter-spacing: ${({ upperCase }) => (upperCase ? '0.1em' : 0)};
  ${({ upperCase }) => upperCase && 'text-transform: uppercase;'}
`;
