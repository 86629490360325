import React from 'react';

import { useSiteMetadata } from '../hooks';

interface SEOProps {
  title?: string;
  description?: string;
  pathname?: string;
  children?: JSX.Element | JSX.Element[];
}
export const Seo = ({ title, description, pathname, children }: SEOProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="url" content={seo.url} />
      {children}
    </>
  );
};
