export const debounceTimer = (func: Function, delay: number) => {
  let timerId: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(null, ...args);
    }, delay);
  };
};
