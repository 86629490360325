import { HeadFC, PageProps } from 'gatsby';
import React, { FC } from 'react';

import { Seo } from '../../../components/Seo';
import {
  ColorBarExtremes,
  ColorPalette,
  DesignLayout,
  ElementCard,
  GradientBar,
  VersionCard,
} from '../../../components/design';
import { SubsectionText } from '../../../components/design/cards/style';
import {
  ColorsContainerStyle,
  StatesContainerStyle,
} from '../../../components/design/colors/styles';
import { darkTheme } from '../../../theme';

const ColorsPage: FC<PageProps> = () => {
  return (
    <DesignLayout>
      <VersionCard title="Colors" />

      <ElementCard title="Primary">
        <ColorBarExtremes>
          <ColorPalette objectColors={darkTheme.primary} />
        </ColorBarExtremes>
      </ElementCard>

      <ElementCard title="Secondary">
        <ColorBarExtremes>
          <ColorPalette objectColors={darkTheme.secondary} />
        </ColorBarExtremes>
      </ElementCard>

      <ElementCard title="Neutral">
        <ColorBarExtremes>
          <ColorPalette objectColors={darkTheme.neutral} />
        </ColorBarExtremes>
      </ElementCard>

      <ElementCard title="States">
        <StatesContainerStyle>
          <div className="state-section">
            <SubsectionText>Confirmed</SubsectionText>
            <ColorsContainerStyle>
              <ColorPalette
                cornerRadius
                objectColors={darkTheme.stateConfirmed}
              />
            </ColorsContainerStyle>
          </div>

          <div className="state-section">
            <SubsectionText>Error</SubsectionText>
            <ColorsContainerStyle>
              <ColorPalette cornerRadius objectColors={darkTheme.stateError} />
            </ColorsContainerStyle>
          </div>

          <div className="state-section">
            <SubsectionText>Alert</SubsectionText>
            <ColorsContainerStyle>
              <ColorPalette cornerRadius objectColors={darkTheme.stateAlert} />
            </ColorsContainerStyle>
          </div>
        </StatesContainerStyle>
      </ElementCard>

      <ElementCard title="Gradients">
        <SubsectionText>Alert</SubsectionText>
        <GradientBar
          leftLabel="Primary 80"
          rightLabel="Primary 60"
          leftColor={darkTheme.gradients.blue.primary80}
          rightColor={darkTheme.gradients.blue.primary60}
        />
        <SubsectionText>Aqua</SubsectionText>
        <GradientBar
          leftLabel="Primary 70"
          rightLabel="Secondary 50"
          leftColor={darkTheme.gradients.aqua.primary70}
          rightColor={darkTheme.gradients.aqua.secondary50}
        />
        <SubsectionText>Black</SubsectionText>
        <GradientBar
          leftLabel="Neutral 100"
          rightLabel="Neutral 70"
          leftColor={darkTheme.gradients.black.neutral100}
          rightColor={darkTheme.gradients.black.neutral70}
        />
        <SubsectionText>Gray</SubsectionText>
        <GradientBar
          leftLabel="Neutral 50"
          rightLabel="Neutral 30"
          leftColor={darkTheme.gradients.gray.neutral50}
          rightColor={darkTheme.gradients.gray.neutral30}
        />
      </ElementCard>
    </DesignLayout>
  );
};

export default ColorsPage;
export const Head: HeadFC = () => <Seo title="Theme" />;
