import { graphql, useStaticQuery } from 'gatsby';

interface SiteMetadata {
  title: string;
  description: string;
  siteUrl: string;
}

interface QueryTypes {
  site: {
    siteMetadata: SiteMetadata;
  };
}
export const useSiteMetadata = () => {
  const data = useStaticQuery<QueryTypes>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
