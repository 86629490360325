import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';

import { NavAction, NavState } from '.';
import { NavRoute } from '../../models';
import { debounceTimer } from '../../utilities';

interface SectionValues {
  bottom: number;
  path: string;
  top: number;
}

export const useNav = (
  state: NavState,
  dispatch: React.Dispatch<NavAction>,
) => {
  const location = useLocation();
  const [context, setContext] = useState('');
  const [sectionsLimits, setSectionsLimits] = useState<SectionValues[]>();

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const filterRouteWithHash = state.routes.find(
      (route) => route.path === location.hash,
    );

    if (filterRouteWithHash) {
      timerId = setTimeout(() => {
        setContext('click');
        setRouteWithScroll(filterRouteWithHash.path);
        setSectionsLimits(getSectionsLimits(state.routes));
      }, 1000);
      return;
    }

    timerId = setTimeout(() => {
      setSectionsLimits(getSectionsLimits(state.routes));
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setContext('scroll');
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      const path = getPathFromLimits(sectionsLimits, scrollPosition);
      setRouteWithScroll(path);

      // state.isOpen && dispatch({ type: 'closeNav' }); // TODO: menu mobile - automatic closing of the menu when scrolling
    };

    const handleResize = () => {
      setSectionsLimits(getSectionsLimits(state.routes));
    };

    const resizeTimer = debounceTimer(handleResize, 500);
    const scrollTimer = debounceTimer(handleScroll, 100);

    window.addEventListener('scroll', scrollTimer);
    window.addEventListener('resize', resizeTimer);

    handleScroll();
    return () => {
      window.removeEventListener('scroll', scrollTimer);
      window.removeEventListener('resize', resizeTimer);
    };
  }, [sectionsLimits]);

  useEffect(() => {
    if (context === 'click') {
      const route = state.routes.find((r) => r.active);
      const element = route ? document.querySelector(route?.path) : null;
      if (element instanceof HTMLElement) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [state.routes]);

  function setRouteWithScroll(routePath: string) {
    if (routePath.trim().length === 0) return;

    const newRouteState = state.routes.map((route) => ({
      ...route,
      active: route.path === routePath,
    }));

    dispatch({
      type: 'setRoutes',
      payload: { routes: newRouteState, activePath: routePath },
    });
  }

  function getSectionsLimits(navRoutes: NavRoute[]) {
    const list: SectionValues[] = [];
    navRoutes.forEach((route) => {
      const element = document.querySelector(route.path);
      if (element instanceof HTMLElement) {
        list.push({
          path: route.path,
          top: element.offsetTop,
          bottom: element.offsetTop + element.offsetHeight,
        });
      }
    });

    return list;
  }

  function getPathFromLimits(
    sectionsValues: SectionValues[] | undefined,
    scrollValue: number,
  ) {
    let path = '';

    sectionsValues?.forEach((section, i) => {
      const endLimit =
        i === sectionsValues.length - 1
          ? section.bottom
          : sectionsValues[++i].top;

      if (!section) return;
      if (scrollValue >= section.top - 2 && scrollValue <= endLimit) {
        path = section.path;
      }
    });

    return path;
  }

  return { setContext };
};
