export const currentDate = new Date();
export const currentMonth = currentDate.getMonth() + 1;
export const currentYear = currentDate.getFullYear();

const monthAbbrev = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthNumberToString = (monthNumber: number) => {
  if (monthNumber >= 0 && monthNumber <= 11) {
    return monthAbbrev[monthNumber];
  }
};
