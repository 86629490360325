import React, { FC, useReducer } from 'react';

import { NavContext, navReducer } from '.';
import { useNav } from './useNav';
import { NavRoute, routes } from '../../models';

export interface NavState {
  activePath?: string;
  hideNav: boolean;
  isOpen: boolean;
  routes: NavRoute[];
}

const INTIAL_STATE: NavState = {
  hideNav: false,
  isOpen: false,
  routes,
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const NavProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(navReducer, INTIAL_STATE);
  const { setContext } = useNav(state, dispatch);

  const routeSelector = (value: -1 | 1) => {
    setContext('click');
    let i = 0;
    const newRouteState = state.routes.map((route, index) => {
      route.active && (i = index + value);
      return {
        ...route,
        active: false,
      };
    });
    if (i < 0 || i >= state.routes.length) return;
    newRouteState[i].active = true;

    dispatch({
      type: 'setRoutes',
      payload: { routes: newRouteState, activePath: newRouteState[i].path },
    });
  };

  const setRoute = (routePath: string) => {
    setContext('click');
    const newRouteState = routes.map((route) => ({
      ...route,
      active: route.path === routePath,
    }));

    dispatch({
      type: 'setRoutes',
      payload: { routes: newRouteState, activePath: routePath },
    });
  };

  const setHideNav = (value: boolean) => {
    dispatch({ type: 'setHideFooter', payload: value }); // Hide navbar
  };

  const toogleNav = () => {
    dispatch({ type: 'toogleNav' });
  };

  return (
    <NavContext.Provider
      value={{
        ...state,

        //Methods
        routeSelector,
        setHideNav,
        setRoute,
        toogleNav,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
