import { NavState } from './NavProvider';
import { NavRoute } from '../../models';

export type NavAction =
  | { type: 'setRoutes'; payload: { routes: NavRoute[]; activePath: string } }
  | { type: 'toogleNav' }
  | { type: 'setHideFooter'; payload: boolean };

export const navReducer = (state: NavState, action: NavAction): NavState => {
  switch (action.type) {
    case 'setRoutes':
      return {
        ...state,
        routes: action.payload.routes,
        activePath: action.payload.activePath,
      };

    case 'toogleNav':
      return { ...state, isOpen: !state.isOpen };

    case 'setHideFooter':
      return { ...state, hideNav: action.payload };

    default:
      return state;
  }
};
