export const dataBlog = [
  {
    blogUrl:
      'https://medium.com/@draxo_team/why-is-cybersecurity-such-an-important-issue-in-the-digital-age-e1ef7de0600e',
    title: 'Why is cybersecurity such an important issue in the digital age?',
    imageUrl:
      'https://miro.medium.com/v2/resize:fit:1400/format:webp/1*vN9WYYNiBUBLXMYHL-Rplg.jpeg',
    category: 'Cybersecurity',
    date: 'May 26, 2023',
  },
  {
    blogUrl:
      'https://medium.com/@draxo_team/manual-testing-in-2023-is-it-still-working-9e0eb54a1751',
    title: 'Manual Testing in 2023, is it still working?',
    imageUrl:
      'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*21JBhrZoKoKkzNCE-w23wg.jpeg',
    category: 'Testing',
    date: 'May 19, 2023',
  },
  {
    blogUrl:
      'https://medium.com/@draxo_team/react-js-vs-react-native-whats-the-difference-7c598b391b7b',
    title: 'React.js vs React Native - What’s the Difference?',
    imageUrl:
      'https://miro.medium.com/v2/resize:fit:1100/format:webp/1*cC9PQ00bv1_1CePwcttLhg.jpeg',
    category: 'React Native',
    date: 'May 10, 2023',
  },
];
